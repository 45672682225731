import CTA from "../Components/Home/CTA";
import FAQ from "../Components/Home/FAQ";
import Hero from "../Components/Home/Hero";
import WhyCardome from "../Components/Home/WhyCardome";
import WithCardome from "../Components/Home/WithCardome";

const Home = () => {
  return (
    <>
      <Hero />
      <WhyCardome />
      <WithCardome />
      <FAQ />
      <CTA />
    </>
  )
}

export default Home;