import Select from 'react-select';
import Cars from '../Components/FindCar/Cars';
import Map from '../Components/FindCar/Map';

const options = [
  { value: 'jetzt', label: 'Jetzt1' },
  { value: 'jetzt1', label: 'Jetzt2' },
  { value: 'jetzt2', label: 'Jetzt3' }
]
const FindCar = () => {
  return (
    <div className="container">
      <div className="find-car-input-wrapper d-flex">
        <input className='location' type="text" defaultValue="Gib Deinen Sandort ein" />
        <Select options={options} components={{ IndicatorSeparator: () => null }} placeholder="Jetzt" className='time-now-wrapper' classNamePrefix="time-now" />
        <Select options={options} components={{ IndicatorSeparator: () => null }} placeholder="Fahrzeugtyp wählen" className='select-type-container' classNamePrefix="select-type" />
        <div className='seats'>
          <span className='text'>Anzahl Sitze</span> <span className='count'>2</span>
        </div>
      </div>
      <div className="instant-booking-wrapper d-flex justify-content-between align-items-center">
        <div className='instant-booking d-flex align-items-center'>
          <div className="form-check form-switch">
            <input className="form-check-input booking-check" type="checkbox" role="switch" id="flexSwitchCheckChecked" defaultChecked />
            <label className="form-check-label booking-label" htmlFor="flexSwitchCheckChecked">Sofortbuchung</label>
          </div>
          <Select options={options} components={{ IndicatorSeparator: () => null }} placeholder="Radius" className='radius-container' classNamePrefix="radius" />
          <Select options={options} components={{ IndicatorSeparator: () => null }} placeholder="Kraftstoff" className='kraftstoff-container' classNamePrefix="kraftstoff" />
        </div>
        <div>
          <div className="form-check form-switch">
            <input className="form-check-input show-map-check" type="checkbox" role="switch" id="flexSwitchCheckChecked" defaultChecked />
            <label className="form-check-label show-map-label" htmlFor="flexSwitchCheckChecked">Karte anzeigen</label>
          </div>
        </div>
      </div>
      <div className="result-wrapper">
        <div className="row">
          <Cars />
          <Map />
        </div>
      </div>
    </div>
  )
}

export default FindCar;