import { useNavigate } from "react-router-dom";

const WithCardome = () => {
  const navigate = useNavigate()
  return (
    <div className="with-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="row g-0">
              <div className="col-md-6">
                <div className="left">
                  <h2 className="title">Werde Vermieter bei Shary</h2>
                  <p className="text">Lass dein Auto Geld verdienen ! Ob nur ein Fahrzeug oder ganzer  Fuhrpark, nimm deine Finanzen selbst in die Hand und fange heute an Geld mit deinem Auto zu verdienen.</p>
                  <p className="text">Shary hilft dir dein Fahrzeug optimal zu nutzen und nebenbei mit deinem Auto bares Geld zu verdienen. Angehende Autovermieter erhalten mit Shary die richtigen Tools um eine  Flotte aufzubauen und diese lukrativ  zu vermieten.
                    Starte jetzt unternehmerisch mit Shary durch!</p>
                  <p className="text">Verwalte Dein Geschäft und Deine Buchungen nahtlos von unterwegs aus - nimm Fahrten an, passe Deine Preise an, schreibe Deinen Gästen Nachrichten und vieles mehr - alles von Deinem Handy aus.</p>
                  <button className="with-btn" onClick={() => navigate("/findcar")}>Vermiete dein Auto</button>
                </div>
              </div>
              <div className="col-md-6 right"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WithCardome;