import Slider from "react-slick";
import img1 from '../../assets/images/slider/1.jpg';
import location from '../../assets/images/icon/location.svg';
import star from '../../assets/images/icon/star.svg';
const CarSlider = () => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    autoplay: true,
    centerPadding: "500px",
    responsive: [
      {
        breakpoint: 1637,
        settings: {
          centerPadding: "250px",
        }
      },
      {
        breakpoint: 850,
        settings: {
          centerMode: false,
        }
      }
    ]
  };
  return (
    <Slider {...settings}>
      <div className='single-slide'>
        <div className='position-absolute location'>
          <img src={location} alt="" />
          <span>20km</span>
        </div>
        <div className="ratting position-absolute">
          <img src={star} alt="" />
          <span>5.0</span>
        </div>
        <img className='car-img' src={img1} alt="" />
      </div>
      <div className='single-slide'>
        <div className='position-absolute location'>
          <img src={location} alt="" />
          <span>20km</span>
        </div>
        <div className="ratting position-absolute">
          <img src={star} alt="" />
          <span>5.0</span>
        </div>
        <img className='car-img' src={img1} alt="" />
      </div>
      <div className='single-slide'>
        <div className='position-absolute location'>
          <img src={location} alt="" />
          <span>20km</span>
        </div>
        <div className="ratting position-absolute">
          <img src={star} alt="" />
          <span>5.0</span>
        </div>
        <img className='car-img' src={img1} alt="" />
      </div>
      <div className='single-slide'>
        <div className='position-absolute location'>
          <img src={location} alt="" />
          <span>20km</span>
        </div>
        <div className="ratting position-absolute">
          <img src={star} alt="" />
          <span>5.0</span>
        </div>
        <img className='car-img' src={img1} alt="" />
      </div>
      <div className='single-slide'>
        <div className='position-absolute location'>
          <img src={location} alt="" />
          <span>20km</span>
        </div>
        <div className="ratting position-absolute">
          <img src={star} alt="" />
          <span>5.0</span>
        </div>
        <img className='car-img' src={img1} alt="" />
      </div>
    </Slider >
  )
}

export default CarSlider;