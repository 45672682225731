import Select from 'react-select';
import car1 from '../../assets/images/car/1.jpg';
import car2 from '../../assets/images/car/2.jpg';
import car3 from '../../assets/images/car/3.jpg';
import car4 from '../../assets/images/car/4.jpg';
import car5 from '../../assets/images/car/5.jpg';
import car6 from '../../assets/images/car/6.jpg';
import star from '../../assets/images/icon/star.svg';
import menu from '../../assets/images/icon/menu.svg';
import menuList from '../../assets/images/icon/menu-list.svg';
import { Link } from 'react-router-dom';
const options = [
  { value: 'jetzt', label: 'Jetzt1' },
  { value: 'jetzt1', label: 'Jetzt2' },
  { value: 'jetzt2', label: 'Jetzt3' }
]
const Cars = () => {
  return (
    <div className="col-md-8">
      <div className="cars-top d-flex justify-content-between align-items-center">
        <span className='result'>20 Ergebnisse</span>
        <div className='layout d-flex'>
          <div className='single-layout active'>
            <img src={menu} alt="" />
          </div>
          <div className='single-layout '>
            <img src={menuList} alt="" />
          </div>
        </div>
        <Select options={options} components={{ IndicatorSeparator: () => null }} placeholder="Sortiert nach" className='sort-container' classNamePrefix="sort" />
      </div>
      <div className="row">
        {[car1, car2, car3, car4, car5, car6].map((item, i) => (<div key={i} className="col-sm-6">
          <Link to={`/car-details/${i}`} className="single-car">
            <img className='car-img' src={item} alt="" />
            <div className="car-content">
              <h3 className='car-name'>Autoname</h3>
              <p className='model'>Model 3</p>
              <div className="car-info d-flex justify-content-between align-items-end">
                <div className='price-wrapper d-flex align-items-end'>
                  <span className='price'>30 €</span> <span className='price-text'>für 2 std</span>
                </div>
                <div className='ratting d-flex align-items-center'>
                  <img src={star} alt="star" />
                  <span className='count'>5.0</span>
                </div>
              </div>
            </div>
          </Link>
        </div>))}
      </div>
    </div>
  )
}

export default Cars;