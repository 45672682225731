import logo from '../../assets/images/logo.png';
import fb from '../../assets/images/social/fb.svg';
import twitter from '../../assets/images/social/twitter.svg';
import youtube from '../../assets/images/social/youtube.svg';
import linkdin from '../../assets/images/social/linkdin.svg';
import instagram from '../../assets/images/social/instagram.svg';
const Footer = () => {
  return (
    <div className="container">
      <div className="footer-top row">
        <div className="col-lg-9">
          <div className='footer-links'>
            <img src={logo} alt="" height={100} />
            <a href="#">support@shary.com</a>
            <a href="#">0228-3617215</a>
          </div>
        </div>
        <div className="col-lg-3">
          <div className='social-icons'>
            <a href="#"><img src={fb} alt="" /></a>
            <a href="#"><img src={twitter} alt="" /></a>
            <a href="#"><img src={youtube} alt="" /></a>
            <a href="#"><img src={linkdin} alt="" /></a>
            <a href="#"><img src={instagram} alt="" /></a>
          </div>
        </div>
      </div>
      <div className="footer-bottom d-flex justify-content-between">
        <p className='copyright-text'>© 2022 Shary. All rights reserved.</p>
        <div className='pages-links d-flex'>
          <a href="#">Impressum</a>
          <a href="#">Datenschutz</a>
        </div>
      </div>
    </div>
  )
}

export default Footer;