import API from './';

const prefix = 'reports';

export const getPayoutsByMail = async () => {
  return await API.get(`${prefix}/payouts`);
};



