import React, { useEffect, useState } from "react";
import firebase from "firebase";
import useUser from "../hooks/useUser";
import { useQuery } from "@tanstack/react-query";
import { getPayoutsByMail } from "../api/reports";

export default () => {
    const user = useUser();
    const { data, isSuccess, refetch } = useQuery([], () => getPayoutsByMail(),
        { enabled: false, refetchOnWindowFocus: false });
        
    useEffect(() => {
        if (isSuccess) alert("Email erfolgreich versendet");
    }, [isSuccess]);

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    return (
        <>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "30vh" }}>
                <input style={{border: "0.5px solid black", height: 44}} placeholder="Email" onChange={(e) => setEmail(e.target.value)} value={email} />
                <input style={{border: "0.5px solid black", height: 44}} type="password" placeholder="Passwort" onChange={(e) => setPassword(e.target.value)} value={password} />
                <button style={{ width: "100px", height: "44px" }} onClick={() => firebase.auth().signInWithEmailAndPassword(email, password)} >ANMELDEN</button>
                {user && <div><button style={{ width: "150px", height: "44px", marginLeft: 25 }} onClick={refetch}>E-MAIL SENDEN</button></div>}

            </div>
        </>);
};