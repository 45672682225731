import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
const options = [
  { value: 'jetzt', label: 'Jetzt1' },
  { value: 'jetzt1', label: 'Jetzt2' },
  { value: 'jetzt2', label: 'Jetzt3' }
]
const options2 = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
]
const Hero = () => {
  const navigate = useNavigate()
  return (
    <div className="hero-area">
      <h1 className="hero-text">Finde das passende Auto für deine Reise <br/>Shary deine Carsharing Community</h1>

      <div className="container">
        <div className="hero-wrapper">
          <div className="input-wrapper">
            {/* <input defaultValue="Gib Deinen Sandort ein" className='location' type="text" />
            <Select options={options} components={{ IndicatorSeparator: () => null }} placeholder="Jetzt" className='time-container' classNamePrefix="time" />
            <Select components={{ IndicatorSeparator: () => null }} options={options2} className="vehicle-type-container" classNamePrefix="vehicle-type" placeholder="Fahrzeugtyp wählen" /> */}
            <button className='find-btn' onClick={() => navigate("/findcar")}>Android Download</button>
            <button className='find-btn' onClick={() => navigate("/findcar")}>iOS Download</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero;