import { GoogleMap, LoadScript } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100%',
  minHeight: 300,
  borderRadius: "40px"
};
const defaultMapOptions = {
  fullscreenControl: false,
  disableDefaultUI: true
};
const center = {
  lat: 51.505,
  lng: -0.09
};
const Map = () => {
  return (
    <div className="col-md-4">
      <LoadScript googleMapsApiKey="AIzaSyB4RM7zOgOKq6n2fv407hX28xiL-M6vLdY">
        <GoogleMap options={defaultMapOptions} mapContainerStyle={containerStyle} center={center} zoom={10} >
        </GoogleMap>
      </LoadScript>
    </div>
  )
}

export default Map;