import logo from '../../assets/images/logo.png';
import avatar from '../../assets/images/avatar.png';
import { Link, useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate()
  return (
    <div className="container header">
      <div className="d-flex align-items-center">
        <div className='flex-grow-1'>
          <Link to="/" className="logo">
            <img src={logo} alt="logo" height={100}/>
          </Link>
        </div>
        <div className="right d-flex align-items-center">
          <a href="#faq-area" className='faq-nav'>FAQ</a>
          <button className='rent-btn' onClick={() => navigate("/findcar")}>Vermiete dein Auto</button>
          <img src={avatar} alt="avatar" className='avatar' />
        </div>
      </div>
    </div>
  )
}

export default Header;