import { useNavigate } from "react-router-dom";

const CTA = () => {
  const navigate = useNavigate()
  return (
    <div className="cta-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="cta-wrapper">
              <div className="cta-content">
                <h2>Shary deine Carsharing Community</h2>
                <p>Finde das passende Auto für deine Reise.</p>
                <button onClick={() => navigate("/findcar")}>Auto finden</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CTA;