import avatar from '../../assets/images/avatar2.png';
import starColor from '../../assets/images/icon/star-color.svg';

const BookingCard = () => {
  return (
    <div className="col-md-6 col-lg-5">
      <div className="booking-card">
        <div className="text-center">
          <h2 className='price mb-0'>30 €</h2>
          <span className='price-text'>für 2 std</span>
        </div>
        <div className="car-owner d-flex align-items-center justify-content-between">
          <div className="name d-flex align-items-center">
            <img src={avatar} alt="" />
            <h4 className='mb-0'>Jackie Forman</h4>
          </div>
          <div className="ratting">
            <div className="star d-flex align-items-center">
              <img src={starColor} alt="" />
              <img src={starColor} alt="" />
              <img src={starColor} alt="" />
              <img src={starColor} alt="" />
              <img src={starColor} alt="" />
            </div>
            <span className='text'>25 Bewertungen</span>
          </div>
        </div>
        <button className="card-btn">Buchung anfragen</button>
      </div>
    </div>
  )
}

export default BookingCard;