import React, { useEffect, useState } from "react";
import { getOwnUser } from "../api/user";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createApplicant,
  getVerificationToken,
} from "../api/verification";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

export default () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState();

  const { data: user, refetch, isSuccess } = useQuery(["ownUser"], () => getOwnUser(), { enabled: false });

  useEffect(() => {
    if (!window.data.token) return;
    localStorage.setItem("token", window.data.token);
    setToken(window.data.token);
  }, []);

  useEffect(() => {
    if (token) return;
    refetch();
  }, [token]);

  useEffect(() => {
    if (!isSuccess || !user?.firstName) return;
    startVerification(user);
  }, [isSuccess]);

  const { mutate: _createApplicant } = useMutation(
    (_user) => createApplicant(),
    {
      onSuccess: (_applicant) => {
        if(_applicant.verificationBlocked){
          alert("Ihre Verifikation ist 3 mal fehlgeschlagen. Eine erneute Verifikation können wir nur gegen eine Gebühr selber durchführen.");
          return;
        }
        _getVerificationToken(_applicant.id);
      },
      onError: (e) => {},
    }
  );

  const { mutate: _getVerificationToken } = useMutation(
    (id) => getVerificationToken(id),
    {
      onSuccess: (data, variables) => {
        setLoading(false);
        const token = JSON.parse(data).token;
        navigate("/verification", {
          state: { token: token, applicantId: variables },
        });
      },
      onError: (e) => { },
    }
  );

  const startVerification = (user) => {
    _createApplicant(user);
  };

  return (
    <div>
      <center>
        <h3>Verifizierung startet...</h3>
        {loading && <Spinner animation="grow" />}
      </center>
    </div>
  );
};
