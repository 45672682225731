import { useMutation, useQuery } from "@tanstack/react-query";
import { init } from "onfido-sdk-ui";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getOwnUser } from "../api/user";
import { createCheck, statusCheck } from "../api/verification";

export default () => {
  const { state } = useLocation();
  const { data } = useQuery(["user"], () => getOwnUser());

  function checkStatus() {
    setInterval(() => {
      statusCheck(data.applicantId).then((res) => {
        const newestCheck = res.checks.sort((a,b) => new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf());
        if (newestCheck[0] && newestCheck[0].status === "complete" && newestCheck[0].result === "clear") {
          alert("Der Verifizierungsprozess ist beendet, du bist nun ein verifizierter Benutzer.");
          window.ReactNativeWebView.postMessage(JSON.stringify({ success: true }));
        }
        if(newestCheck[0] && newestCheck[0].status === "complete" && newestCheck[0].result === "consider")
          alert("Du konntest leider nicht verifiziert werden. Bitte versuche es erneut. Sollte es dann immer noch nicht klappen, wende dich bitte an unseren Support.");
      });
    }, 5000);
  };

  const { mutate: _createCheck } = useMutation(
    () => createCheck(data.applicantId),
    {
      onSuccess: (res) => {
        checkStatus();
      },
      onError: (e) => { },
    }
  );

  useEffect(() => {
    init({
      language: "de_DE",
      token: state.token,
      containerId: "onfido-mount",
      onComplete: function (_) {
        console.warn("COMPLETED!");
        _createCheck();
      },
      customUI: {
        fontFamilyTitle: "Poppins",
        fontFamilySubtitle: "Poppins",
        fontFamilyBody: "Poppins",
        colorBackgroundButtonPrimary: "#7D7AFF",
        borderRadiusButton: 12,
        colorBorderButtonPrimary: "#7D7AFF",
        colorBackgroundButtonPrimaryActive: "#7D7AFF",
        colorBackgroundIcon: "#7D7AFF",
        colorIcon: "#7D7AFF",
        colorInputOutline: "#7D7AFF",
        colorBackgroundSelector: "#7D7AFF",
      },
      steps: [
        {
          type: "welcome",
          options: {
            title: "Profil verifizieren",
            descriptions: ["Verwenden Sie Ihr mobiles Gerät zum Fotografieren:", "1. Personalausweis", "2. Selfie"],
            nextButton: "Los geht's!"
          }
        },
        {
          type: "document",
          options: {
            documentTypes: {
              national_identity_card: true,
            },
            useLiveDocumentCapture: false,
            forceCrossDevice: true,
            uploadFallback: true,
          },
        },
        {
          type: "face",
          options: {
            uploadFallback: true
          }
        },
        {
          type: "complete",
          options: {
             message: "Vielen Dank! Bitte hier warten...",
             submessage: "Die Verifizierung wird gerade durchgeführt. Das sollte höchstens ein paar Minuten dauern. Bitte warte hier solange!"
          }
        }
      ],
    });
  }, []);

  return <div id="onfido-mount"></div>;
};
