const environment = process.env.NODE_ENV;

const config = {
  ApiUrl: "",
  WebUrl: "",
  environment: "",
  firebase: ""
};

if (environment === "development") {
  config.ApiUrl = "https://shary-backend.shadet.de/api";
  config.environment = "development";
  config.firebase = {
    apiKey: 'AIzaSyCTV3_gZVKkp1ExfnoNpTcuTP7uybostXQ',
    authDomain: 'cardome-dccdd.firebaseapp.com',
    projectId: 'cardome-dccdd',
    storageBucket: 'cardome-dccdd.appspot.com',
    messagingSenderId: '1046779766417',
    appId: '1:1046779766417:web:5f70704e794c0c2c2e8130',
    databaseURL: 'https://cardome-dccdd-default-rtdb.europe-west1.firebasedatabase.app',
    measurementId: 'G-832XWK6GTN',
  };
} else if (environment === "production") {
  config.ApiUrl = "https://shary-backend.shadet.de/api";
  config.environment = "development";
  config.firebase = {
    apiKey: 'AIzaSyCTV3_gZVKkp1ExfnoNpTcuTP7uybostXQ',
    authDomain: 'cardome-dccdd.firebaseapp.com',
    projectId: 'cardome-dccdd',
    storageBucket: 'cardome-dccdd.appspot.com',
    messagingSenderId: '1046779766417',
    appId: '1:1046779766417:web:5f70704e794c0c2c2e8130',
    databaseURL: 'https://cardome-dccdd-default-rtdb.europe-west1.firebasedatabase.app',
    measurementId: 'G-832XWK6GTN',
  };
} else {
  console.error("NODE_ENV is wrong, It is neither development or production.");
}

export default config;
