
import CarSlider from '../Components/CarDetails/CarSlider';
import BookingCard from '../Components/CarDetails/BookingCard';
import CarInfo from '../Components/CarDetails/CarInfo';

const CarDetails = () => {
  return (
    <div className="details-info-area">
      <CarSlider />
      <div className="container">
        <div className="row">
          <CarInfo />
          <BookingCard />
        </div>
      </div>
    </div >
  )
}

export default CarDetails;