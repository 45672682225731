import API from ".";

const prefix = "user";

export const getOwnUser = async () => {
  return await API.get(`${prefix}`);
};

export const getUser = async (id) => {
  return await API.get(`${prefix}/${id}`);
};

export const getOwner = async (id) => {
  return await API.get(`${prefix}/owner/${id}`);
};

export const updateProfilePicture = async (image) => {
  const formData = new FormData();

  formData.append("profilePicture", image);

  return await API.put(`${prefix}/profile-picture`, formData);
};

export const updateUser = async (user) => {
  return await API.put(`${prefix}`, user);
};

export const deleteProfilePicture = async () => {
  return await API.delete(`${prefix}/profile-picture`);
};
