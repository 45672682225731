import { Accordion } from "react-bootstrap";

const FAQ = () => {
  return (
    <div id="faq-area" className="faq-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="faq-wrapper">
              <div className="faq-top">
                <span className="faq-badge">FAQ</span>
                <h3 className="title">Häufig gestellte Fragen</h3>
                {/* <p className="subtitle">Take as many pixel-perfect UI elements as you want and style them the way you need in a fraction of the time.</p> */}
              </div>
              <div className="faq-item-wrapper">
                <Accordion defaultActiveKey="0" flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Was benötige ich, um ein Auto auf Shary zu mieten?
                    </Accordion.Header>
                    <Accordion.Body>
                      Um ein Auto auf Shary zu buchen, musst du ein Shary-Konto erstellen,
                      mindestens 21 Jahre alt sein, einen gültigen Führerschein besitzen und von Shary verifiziert sein.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Brauche ich einen eigenen Versicherungsschutz?
                    </Accordion.Header>
                    <Accordion.Body>
                      Nein, Shary stellt die Versicherung, du brauchst keinen eigenen Versicherungsschutz, um ein Auto auf Shary zu buchen.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Können andere Personen das Auto fahren, welches ich buche?
                    </Accordion.Header>
                    <Accordion.Body>
                      Ja, das Fahrzeug welches du bei Shary buchst, kann von mehreren Personen gefahren werden, sofern diese von Shary zum Fahren zugelassen sind.
                      Der Hauptfahrer, derjenigen der das Auto gebucht hat, kann weitere Fahrer hinzufügen ohne, dass weitere Kosten anfallen.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Was sind die Stornierungsbedingungen bei Shary?
                    </Accordion.Header>
                    <Accordion.Body>
                      Bis zu 24 Stunden bevor du deine Reise antrittst, kannst du die Buchung stornieren und erhältst eine volle Rückerstattung. Wenn du deine Reise, mit weniger als 24 Stunden Vorlauf buchst, hast du die Möglichkeit, innerhalb von einer Stunde kostenfrei zu stornieren. Wenn du nach Ablauf der kostenfreien Stornierungsfrist stornierst, fällt eine kleine Stornierungsgebühr an.
                      In einzelnen Fällen kann es passieren, dass der Shary-Vermieter, die Buchung storniert. In einem solchen Fall, wirst du sofort benachrichtigt, sodass du ein anders Auto buchen kannst.
                      Um die Umbuchung zu beschleunigen, kann deine Rückerstattung zurückgehalten werden, oder der Betrag wird auf dein Bankkonto zurücküberwiesen.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Was passiert, wenn ich einen Unfall habe?
                    </Accordion.Header>
                    <Accordion.Body>
                      Wenn es einen Notfall oder ein Problem mit dem Auto gibt, rufst du unseren Pannendienst an, der rund um die Uhr verfügbar ist. Wir sorgen dafür, dass du in Sicherheit bist, und helfen dir dann, deinen Weg fortzusetzen
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>Kann ich das Auto zu mir geliefert bekommen?
                    </Accordion.Header>
                    <Accordion.Body>
                      Ja, viele Anbieter bieten die Lieferung an Verkehrsknotenpunkte wie Flughäfen, Bahnhöfe und Hotels oder an individuelle Lieferorte.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>Wie kriege ich Rabatte auf meine Buchung?
                    </Accordion.Header>
                    <Accordion.Body>
                      Viele Shary-Vermieter bieten ermäßigte Preise für Wochen- und Monatsreisen sowie "Frühbucher"-Rabatte für Reisen, die eine Woche oder länger im Voraus gebucht werden. Die besten Angebote und niedrigsten Preise für alles, vom Auto bis zum Geländewagen, erhältst du, wenn du längere Reisen mindestens eine Woche im Voraus buchen.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>Was sind die Reinigungs- und Sicherheitsrichtlinien bei Shary?
                    </Accordion.Header>
                    <Accordion.Body>
                      Im Rahmen der Reinigungsrichtlinien sind Shary-Vermieter verpflichtet, ihre Fahrzeuge vor jeder Fahrt gründlich zu reinigen und zu desinfizieren, damit du dich hinter dem Steuer sicher und wohl fühlen kannst.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQ;