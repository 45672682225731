import firebase from 'firebase';
import { useEffect, useState } from 'react';

export default function () {
    const [user, setUser] = useState();

    useEffect(() => {
        const subscriber = firebase.auth().onAuthStateChanged((user) => {
            if (!user) setUser(null);
            if (user) {
                user.getIdTokenResult().then((idTokenResult) => {
                    setUser({
                        displayName: user.displayName,
                        email: user.email,
                        id: user.uid
                    });
                });
            }

        })

        return subscriber;
    }, []);

    return user;
}
