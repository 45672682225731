import API from ".";

const prefix = "verification";

export const createApplicant = async () => {
  return await API.post(`${prefix}/applicant`);
};

export const getVerificationToken = async (applicantId) => {
  return await API.post(`${prefix}/sdkToken/${applicantId}`);
};

export const createCheck = async (applicantId) => {
  return await API.post(`${prefix}/createCheck/${applicantId}`);
};

export const statusCheck = async (applicantId) => {
  return await API.post(`${prefix}/statusCheck/${applicantId}`);
};
