import axios from "axios";
import firebase from "firebase";
import config from "../config";

const API = axios.create({
  responseType: "json",
  baseURL: config.ApiUrl,
});

API.interceptors.request.use(async (request) => {
  const token = localStorage.getItem("token");
  request.headers = {
    Authorization: `Bearer ${token}`,
  };
  return request;
});

API.interceptors.response.use(
  (response) => {
    return response.data.resultObject;
  },
  (error) => {
    let status = error.response.status;
    const errors = error.response.data;

    if (status === 500) {
      alert("Some problems with the server :(");
    }

    if (status === 401) {
    } else {
      return errors || error.response.data;
    }
  }
);

export default API;
