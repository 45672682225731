import { Icon } from '@iconify/react';
import bgImage from '../../assets/images/why-cardome.png';

const WhyCardome = () => {
  return (
    <div className='why-area'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="right">
              <span className='why-badge'>Unsere Vorteile</span>
              <h2 className='title'>Warum Shary ?</h2>
              <p className='subtitle'>Shary ist deine Carsharing Gemeinschaft - hier findest du sowohl gewerbliche als auch private Autobesitzer aus deiner Nähe.
                Egal ob Kleinwagen, Limousine oder Transporter, bei Shary hat den perfekten Wagen für deinen Anlass. Vereinbare einfach Ort und Zeit mit dem Fahrzeug-Besitzer und beginne deine Reise!</p>
              <div className="why-list">
                <div className="list-item">
                  <Icon icon="ci:circle-check-outline" color="#52c41a" width="20" height="20" />
                  <span className='text'>Versicherung inklusive</span>
                </div>
                <div className="list-item">
                  <Icon icon="ci:circle-check-outline" color="#52c41a" width="20" height="20" />
                  <span className='text'>Sicherheit & Unterstützung</span>
                </div>
                <div className="list-item">
                  <Icon icon="ci:circle-check-outline" color="#52c41a" width="20" height="20" />
                  <span className='text'>Eine einfach zu bedienende App</span>
                </div>
                <div className="list-item">
                  <Icon icon="ci:circle-check-outline" color="#52c41a" width="20" height="20" />
                  <span className='text'>Viele Autobesitzer und Menschen die ein Auto suchen</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 text-center">
            <img className='why-img' src={bgImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyCardome;