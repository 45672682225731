import { Icon } from '@iconify/react';
import { MapContainer, TileLayer } from 'react-leaflet';
import key from '../../assets/images/icon/key.svg';
import file from '../../assets/images/file.jpg';
import info from '../../assets/images/icon/info.svg';
const CarInfo = () => {
  return (
    <div className="col-md-6 col-lg-7">
      <h2 className='head mb-0'>Tesla</h2>
      <span className='head-mini'>Model 3</span>
      <div className="badge-wrapper">
        <div className="row">
          <div className="col-5">
            <span className='badge-text'>Sitzanzahl</span> <span className='badge-count'>4</span>
          </div>
          <div className="col-7">
            <span className='badge-text'>Kraftstoff</span> <span className='badge-count'>Elektro</span>
          </div>
        </div>
      </div>
      <div className="key-box d-flex">
        <img src={key} alt="" />
        <div>
          <h4>Vermieter*in treffen</h4>
          <p className='mb-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis lectus in tellus luctus quis massa.</p>
        </div>
      </div>
      <div className="kilometers">
        <div className='d-flex align-items-center mb-3'>
          <h4 className='title mb-0 me-2'>Kilometer inklusive</h4>
          <img src={info} alt="" />
        </div>
        <div className='d-flex align-items-center'>
          <Icon icon="ci:circle-check-outline" color="#52c41a" width="27" height="27" className='me-3' />
          <p className='mb-0'>40km kostenlos</p>
        </div>
      </div>
      <div className="tecnical-list">
        <h4 className='title'>Technische Daten</h4>
        <div className="list-item">
          <span>Baujahr</span>
          <span className='right'>2018</span>
        </div>
        <div className="list-item">
          <span>Kilometerstand</span>
          <span className='right'>100.000 km</span>
        </div>
        <div className="list-item">
          <span>Kraftstoff</span>
          <span className='right'>Elektro</span>
        </div>
        <div className="list-item">
          <span>Getriebe</span>
          <span className='right'>Automatisch</span>
        </div>
      </div>
      <div className="location-box">
        <h4 className="title">Übergabe- & Rückgabeort</h4>
        <span className='subtitle'>Musterstraße, 53474 Ahrweiler</span>
        <MapContainer center={[51.505, -0.09]} zoom={13} style={{ height: "170px", borderRadius: "26px", marginBottom: "35px" }}>
          <TileLayer tileSize={512} zoomOffset={-1}
            url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicmFraWJyYXphIiwiYSI6ImNreno2dmt2dzA3ZHczaXM0ZXN5cHZ4cDcifQ.XZHdZt-HBW_ANQ-CSpLw_A`}
          />
        </MapContainer>
      </div>
      <div className="insurance-box">
        <h4 className='title'>Versicherung und Pannendienst</h4>
        <img src={file} alt="" />
      </div>
      <div className="condition-box">
        <h4 className="title">Bedingungen</h4>
        <div className='d-flex align-items-center'>
          <Icon icon="ci:circle-check-outline" color="#52c41a" width="27" height="27" className='me-3' />
          <p className='mb-0'>Über 18 Jahre alt und 2 Jahre Fahrzeugerfahrung</p>
        </div>
      </div>
    </div>
  )
}

export default CarInfo;