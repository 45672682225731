import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import Config from "./config";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./Components/Common/Layout";
import CarDetails from "./pages/CarDetails";
import FindCar from "./pages/FindCar";
import Home from "./pages/Home";
import Payment from "./payment/payment";
import Verification from "./verification/verification";
import Login from "./pages/Login";
import useUser from "./hooks/useUser";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PreVerify from "./pages/Verification";
import PaymentResultSuccess from "./payment/paymentResultSuccess";
import PaymentResultError from "./payment/paymentResultError";

function App() {
  useEffect(() => {
    if (!firebase.apps.length) firebase.initializeApp(Config.firebase);
  }, []);

  const user = useUser();
  const queryClient = new QueryClient();
  if(!firebase.apps.length) return;

  return (
    <QueryClientProvider client={queryClient}>
      {Config.environment === "development" && <div>TEST MODE</div>}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="findcar" element={<FindCar />} />
          <Route path="car-details/:id" element={<CarDetails />} />
        </Route>
        <Route path="verify" element={<PreVerify />} />
        <Route path="payment" element={<Payment />} />
        <Route path="verification" element={<Verification />} />
        <Route path="payment-result-success" element={<PaymentResultSuccess />} />
        <Route path="payment-result-error" element={<PaymentResultError />} />
      </Routes>
    
    </QueryClientProvider>
  );
}

export default App;
